import {axios} from '@/utils/request'

const api = {
    list: '/statistics.VehicleBill/list',
    edit: '/statistics.VehicleBill/edit',
    cost_list: '/statistics.VehicleBill/getCostList',
    getTotalCost: '/statistics.VehicleBill/getTotalCost',
    getTotalRevenue: '/statistics.VehicleBill/getTotalRevenue',
    getAllMonthTotalList: '/statistics.VehicleBill/getAllMonthTotalList',
    yearMonth: '/statistics.VehicleBill/yearMonth',
    gettonji: '/statistics.VehicleBill/getTotalData',
    contrastList: '/statistics.VehicleBill/contrastList',
}

export function list(params) {
    return axios({
        url: api.list,
        method: 'get',
        params
    })
}

export function contrastList(params) {
    return axios({
        url: api.contrastList,
        method: 'get',
        params
    })
}

export function edit(data) {
    return axios({
        url: api.edit,
        method: 'post',
        data
    })
}

export function costList(params) {
    return axios({
        url: api.cost_list,
        method: 'get',
        params
    })
}

export function getTotalCost(params) {
    return axios({
        url: api.getTotalCost,
        method: 'get',
        params
    })
}

export function getAllMonthTotalList(params) {
    return axios({
        url: api.getAllMonthTotalList,
        method: 'get',
        params
    })
}

export function getTotalRevenue(params) {
    return axios({
        url: api.getTotalRevenue,
        method: 'get',
        params
    })
}

export function gettonji(params) {
    return axios({
        url: api.gettonji,
        method: 'get',
        params
    })
}

export function yearMonth(params) {
    return axios({
        url: api.yearMonth,
        method: 'get',
        params
    })
}
