<template>
  <a-modal
      :title="title"
      :width="820"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-button size="small" type="primary" @click="handleAddExtraExpenses">增加一项</a-button>
      <div class="table">
        <div class="tr">
          <div class="td">开支名称</div>
          <div class="td">金额（元）</div>
          <div class="td">操作</div>
        </div>
        <div class="tr" v-for="(item,index) in extra_expenses_list">
          <div class="td">
            <a-input placeholder="请输入开支名称" v-model="item.name"/>
          </div>
          <div class="td">
            <a-input-number style="width: 100%" placeholder="请输入当前开支金额" v-model="item.amount"/>
          </div>
          <div class="td">
            <a @click="handleDelExtraExpenses(index)">删除</a>
          </div>
        </div>
      </div>

    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/statistics/vehicleBill'
import {UploadFile} from '@/components'
import * as vehicleApi from "@/api/vehicle";
import {isEmpty} from "@/utils/util";

export default {
  props: ['vb_id', 'vehicle_id'],
  components: {
    UploadFile
  },
  data() {
    return {
      // 对话框标题
      title: '编辑额外开支',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      vb_id: [],
      extra_expenses_list: [{name: '', amount: '', type: 1, vehicle_ids: [this.vehicle_id]}],
      vehicle_list: []
    }
  },
  methods: {

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleAddExtraExpenses() {
      this.extra_expenses_list.push({name: '', amount: '', type: 1, vehicle_ids: [this.vehicle_id]})
    },
    handleDelExtraExpenses(index) {
      // if (this.extra_expenses_list.length <= 1) {
      //   this.$message.error('至少保留一项')
      //   return false
      // }
      this.extra_expenses_list.splice(index, 1)
    },
    handleSelectAll(index) {
      this.extra_expenses_list[index].vehicle_ids = this.vehicle_list.map(item => {
        return item.vehicle_id
      })
    },

    open({vb_id, extra_expenses_list}) {
      this.visible = true
      this.vb_id = vb_id

      if (!isEmpty(extra_expenses_list)) {
        this.extra_expenses_list = extra_expenses_list
      }
      this.getVehicle()
    },


    getVehicle() {
      vehicleApi.getSelectList().then(({data: {list}}) => {
        this.vehicle_list = list
      })
    },
    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      Api.edit({vb_id: this.vb_id, form: {extra_expenses: this.extra_expenses_list}}).then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }
  }
}
</script>
<style lang="less" scoped>
.table {
  border: 1px #e8e8e8 solid;
  margin-top: 10px;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      width: 100%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;


      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
